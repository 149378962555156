import React, { Component } from 'react';

export default class ProductsMainSection extends Component {
    render() {
        let { title, products, typeOfTitle, addBreakBetweenTitle, thinTitle, boldTitle } = this.props;
        return (
            <section id="products-container" className="pb-120">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col txt-align-center">
                            <h2 className="default-primary-color mb-60">
                                {typeOfTitle === 'thinBold' &&
                                    <>
                                        <span className="thin">{thinTitle}</span>{addBreakBetweenTitle === true ? <br /> : <span className="spacing"></span>}
                                        <span className="bold">{boldTitle}</span>
                                    </>
                                }
                                {typeOfTitle === 'normal' &&
                                    <>
                                        {title}
                                    </>
                                }
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        {products && products.map(({ ID, parentSlug, slug, title, thumbnailImage }, i) =>
                            <div key={`${ID}-product-home-${i}`} className="col-4" >
                                <a href={`/pages/${parentSlug ? parentSlug.join('/') : ''}/${slug}/`} className="card card-overlay" title="Go to {page}" style={{ backgroundImage: `url(${thumbnailImage ? thumbnailImage.sizes['thumbnail-img'] : ''})` }}>
                                    <div className="card-gradient" />
                                    <div className="card-img-overlay">
                                        <h3 className="default-secondary-color">
                                            <span dangerouslySetInnerHTML={{__html: title}} />
                                            <div className="deco-border-small primary-bg-color" />
                                        </h3>
                                    </div>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    }
}