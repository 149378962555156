import React, { Component } from 'react';

export default class Button extends Component {
    render() {
        let {titleButton, buttonLink } = this.props;
        return (
            <>
            {titleButton &&
                <div className="container-fluid">
                    <div className="row mt-60">
                        <div className="col-4 offset-md-4 txt-align-center">
                            <a href={buttonLink} target="_blank" className="btn btn-primary" rel="noopener noreferrer">{titleButton}</a>
                        </div>
                    </div>
                </div>
            }
            </>
        )
    }
}