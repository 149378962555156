import React, { Component } from 'react';
import { BASE_URL } from '../../constant';
import Preloader from '../subComponents/preloader';

// COMPONENTS
import ParallaxSection from '../subComponents/parallaxSection';
import DynamicScrollToTop from '../dynamicScrollToTop';
import DualContent from '../subComponents/dual-content';
import {
    Heading1,
    Heading2,
    Text,
    ThreeImages
} from '../subComponents/dual-content-blocks';
export default class Company extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
        };
    }

    getPageData = slug => {
        fetch(`${BASE_URL}get-posts/?post-type=page&slug=${slug}`)
            .then(res => res.json())
            .then(page => this.setState({ ...page, fetching: false }));
    }

    componentDidMount() {
        this.getPageData(this.props.slug);
    }

    render() {
        let { fetching, contentBlocks } = this.state;
        if (fetching) return <Preloader />;
        return (
            <main id="main" className="triple-inset content-templates">
                <DynamicScrollToTop />
                {contentBlocks && contentBlocks.map(({ acf_fc_layout, ...props }, i) =>
                    <React.Fragment key={`content-block-${i}`}>
                        {acf_fc_layout === 'dualContent' && <DualContent {...props} />}
                        {acf_fc_layout === 'heading' && <Heading1 title={props.heading} />}
                        {acf_fc_layout === 'text' && <Text {...props} />}
                        {acf_fc_layout === 'threeImages' &&
                            <div class="container-fluid triple-inset">
                                <div class="row justify-content-md-center">
                                    <div class="col-5">
                                        <ThreeImages {...props} />
                                    </div>
                                </div>
                            </div>
                        }
                        {acf_fc_layout === 'backgroundImageSection' && <ParallaxSection {...props} />}
                        {acf_fc_layout === 'heading_2' &&
                            <div className={`container-fluid${i > 0 ? ' mrg-top-triple' : ''}`}>
                                <Heading2 title={props.heading} align={props.align} />
                            </div>
                        }

                    </React.Fragment>
                )}
            </main >
        );
    }
}