import React, { Component } from 'react';
import { BASE_URL } from '../../../constant';
import Form from '../contact/form';
import GoogleMaps from '../../subComponents/googleMaps';
import DynamicScrollToTop from '../../dynamicScrollToTop';
import Preloader from '../../subComponents/preloader';

export default class ContactIndex extends Component {

      constructor(props) {
            super(props);

            this.state = {
                  registrationLoading: false,
                  showFormError: false,
                  showFormSuccess: false,
            }
      }

      getPageData = slug => {
            fetch(`${BASE_URL}get-posts/?post-type=page&slug=${slug}`)
                  .then(res => res.json())
                  .then(page => this.setState({ ...page, fetching: false }));
      }

      componentDidMount() {
            this.getPageData(this.props.slug);
      }

      onRegister = async (results) => {
            let errors = results.map(r => r.error).filter(e => e != null);
            if (errors.length > 0) {
                  this.setState({ showFormError: true, showFormSuccess: false });
            }
            else {
                  this.setState({ showFormError: false, showFormSuccess: true });
            }
      }

      render() {
            let { fetching, showFormError, showFormSuccess, registrationLoading, title, description, mapTitle, googleMap, backgroundImageNew } = this.state;
            let bgImg = backgroundImageNew ? backgroundImageNew.sizes['img-fullscreen'] : '';
            if (fetching) return <Preloader />;
            return (
                  <main id="main" className="triple-inset content-templates">
                        <div className="container-fluid reset-inset">
                              <div className="mb-60">
                                    <div className="container-fluid fs-screen-img fs-screen-img-bottom fs-screen-img-parallax" style={{ backgroundImage: `url(${bgImg})` }}>
                                          <div className="masked"></div>
                                          <div className="row align-items-center h-550">
                                                <div className="box-compact">
                                                      {title && <h1 className="default-secondary-color mb-5 as-h1">{title}</h1>}
                                                      <div dangerouslySetInnerHTML={{ __html: description }} ></div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div className="container-fluid inset">
                              <DynamicScrollToTop />
                              <div className="container-fluid">
                                    <div className="row">
                                          <div className="col offset-md-1">
                                                <h2 className="default-primary-color">Contact Us</h2>
                                                <div className="deco-border-large primary-bg-color mt-4 mb-4"></div>
                                          </div>
                                    </div>
                                    <div className="row">
                                          <div className="col-10 offset-md-1">
                                                <div className="row">
                                                      <div className="col-12 double-inset">
                                                            <Form
                                                                  register={this.onRegister}
                                                                  info={this.info}
                                                                  showFormError={showFormError}
                                                                  showFormSuccess={showFormSuccess}
                                                                  registrationLoading={registrationLoading}
                                                            />
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="col-1"></div>
                                    </div>
                              </div>
                        </div>
                        <div className="container-fluid triple-inset">
                              <div className="row">
                                    <div className="col-12 reset-inset txt-align-center">
                                          <h2 className="default-primary-color mb-5"><i className="fas fa-map-marker-alt"></i> {mapTitle}</h2>
                                          <GoogleMaps {...googleMap}/>
                                    </div>
                              </div>
                        </div>
                  </main>
            );
      }
}