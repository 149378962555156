import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../constant';

// COMPONENTS
import SubNavigation from './subNavigation';

// SVGs
import MenuIcon from '../../assets/svg/menu-menu.svg';
import MenuClose from '../../assets/svg/close.svg';

export default class Header extends Component {
    constructor() {
        super();
        this.state = {
            showSubNav: false,
            navigation: 'close-nav',
            showVideo: false,
        };
    }
    componentDidMount() {
        fetch(`${BASE_URL}get-layout/?post-type=options-header`)
            .then(res => res.json())
            .then(({ menu_items, social_links }) => this.setState({ menuItems: menu_items, socialLinks: social_links }));
    }

    openMobileNav = ev => {
        ev.preventDefault();
        document.querySelector('#menu-nav').style.display = 'none';
        document.querySelector('.nav-close').style.display = 'block';
        document.querySelector('#mobile-first-nav').style.right = document.querySelector('#main-navigation').offsetWidth  + 'px';
        document.querySelector('#main-navigation').style.right = '0px';
    }

    closeMobileNav = ev => {
        document.querySelector('#menu-nav').style.display = 'block';
        document.querySelector('.nav-close').style.display = 'none';
        document.querySelector('#mobile-first-nav').style.right = '0';
        document.querySelector('#main-navigation').style.right = '-' + document.querySelector('#main-navigation').offsetWidth  + 'px';
        document.querySelector('#products-nav').classList.remove('active');

        this.setState({ showSubNav: false });
    }

    toggleNavigation = (nav, ev) => {
        this.setState({ navigation: nav });
        ev.preventDefault();
    }

    showSubNav = (selectedSubMenuitems, ev) => {
        this.setState({ showSubNav: !this.state.showSubNav, selectedSubMenuitems });
        ev.preventDefault();
    }

    videoFullScreen = () => {
        this.setState({ showVideo: !this.state.showVideo});

        var iframe = document.getElementById('player');

        iframe.src = 'https://www.youtube.com/embed/r2Q8BDi9WvQ';
    }

    videoFullScreenClose = () => {
        this.setState({ showVideo: false });
        var iframe = document.getElementById('player');

        iframe.src = '';
    }

    render() {
        let { menuItems, socialLinks, selectedSubMenuitems, showSubNav, showVideo, videoFactoryYoutubeID } = this.state;
        if (!menuItems) return null;
        return (
            <header id="header">
                <div id="mobile-first-nav" className="primary-bg-color">
                    <nav id="navigation" role="navigation">
                        <ul className="nav flex-column justify-content-end">
                            <li className="nav-item">
                                <a id="menu-nav" href="# " className="nav-link default-secondary-color" onClick={this.openMobileNav}>
                                    <img src={MenuIcon} alt="Open Menu" />
                                </a>
                            </li>
                            <li className="nav-item nav-close">
                                <a href="# " className="nav-link" onClick={this.closeMobileNav}>
                                    <img src={MenuClose} alt="Close Navigation" />
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                {
                    menuItems &&
                    <div id="main-navigation">
                        <nav role="navigation">
                            <ul className="nav p-4 m-0">
                                {menuItems.map(({ has_submenu, link_type, link, submenu_items, title }, i) =>
                                    <li key={`menu-item-${i}`}>
                                        {
                                            has_submenu === 'No' && link_type === 'Internal' &&
                                            <Link to={`/${link.replace(BASE_URL, "pages/")}`} className="default-secondary-color" onClick={this.closeMobileNav}>{title}</Link>
                                        }
                                        {
                                            has_submenu === 'Yes' &&
                                            <div id="products-nav" className={`default-secondary-color${showSubNav ? ' active' : ''}`} onClick={event => this.showSubNav(submenu_items, event)}><i className="fas fa-angle-left"></i> {title}</div>
                                        }
                                    </li>
                                )}
                                {socialLinks && socialLinks.map(({ link, type, title }, i) =>
                                    <li key={`social-link-${i}`}>
                                        <a href={link} target="_blank" rel="noopener noreferrer" title={title}>
                                            <i className={`fab fa-${type} default-secondary-color`}></i>
                                        </a>
                                    </li>
                                )}
                                <li>
                                    <button id="video" className="btn btn-primary mb-15" onClick={this.videoFullScreen}><i className="fas fa-video primary-color"></i> Video Factory</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                }
                {showSubNav && <SubNavigation submenuItems={selectedSubMenuitems} />}
                
                <div id="video-fullscreen" className={`${showVideo ? ' active' : ''}`}>
                    <div id="video-close" onClick={this.videoFullScreenClose}>
                        <img src={MenuClose} alt="Close Navigation" />
                    </div>
                    <div id="video-wrapper">
                        <iframe
                            title="youtube-video-embed"
                            id="player"
                            className="youtube-video"
                            src={`https://www.youtube.com/embed/${videoFactoryYoutubeID}`}
                            frameBorder='0'
                            allow='autoplay; encrypted-media'
                            allowFullScreen
                        />
                    </div>
                </div>
            </header>
        );
    }
}