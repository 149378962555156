import React, { Component } from 'react';
import LogoBuilders from '../../assets/svg/Builders_Logo_ProudlyMadeInAruba-01.svg';
import LogoWandD from '../../assets/svg/Builders_Logo_ProudlyMadeInAruba-2-01.svg';

export default class HeroArea extends Component {
    render() {
        let { backgroundImage, addDescriptionBox, boxTitle, boxDescription, boxButtonTitle, boxButtonLink } = this.props;
        return (
            <div id="hero-area" className="container-fluid fs-screen fs-screen-img" style={{ backgroundImage: `url(${backgroundImage ? backgroundImage.sizes['img-fullscreen'] : ''})` }}>
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col-3 offset-md-1">
                                <h1>
                                    <img className="brand mt-120 animate-01" src={LogoBuilders} alt="Builders Windows &amp; Doors" />
                                    <img className="brand animate-02" src={LogoWandD} alt="Builders Windows &amp; Doors" />
                                </h1>
                                {addDescriptionBox === 'yes' &&
                                <div className="box-compact box-compact-new">
                                    <h2 className="default-secondary-color as-h1">{boxTitle}</h2>
                                    <div className="description" dangerouslySetInnerHTML={{ __html: boxDescription }}></div>
                                    {boxButtonTitle !== '' &&
                                        <a href={boxButtonLink} className="btn btn-primary">{boxButtonTitle}</a>
                                    }
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}