import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { BASE_URL } from '../../constant';

export default class SubNavigation extends Component {
    constructor() {
        super();
        this.state = {
            display: 'block',
        }
    }

    componentWillUpdate() {
        if (this.state.display !== 'block') this.setState({ display: 'block' });
    }

    goToPage = ev => {
        document.querySelector('#menu-nav').style.display = 'block';
        document.querySelector('.nav-close').style.display = 'none';
        document.querySelector('#mobile-first-nav').style.right = '0';
        document.querySelector('#main-navigation').style.right = '-300px';
        document.querySelector('#products-nav').classList.remove('active');
        this.setState({ display: 'none' });
    }

    render() {
        let { submenuItems } = this.props;
        if (!submenuItems) return null;
        let index = 0;
        return (
            <nav id="sub-navigation" style={{ display: this.state.display }} role="navigation">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-4 full-height">
                            <div className="row">
                                <div className="col-12 one-third-height reset-inset">
                                    <Link to={`/${submenuItems[index].link.replace(BASE_URL, "pages/")}`} className="card card-overlay" title={`Go to ${submenuItems[index].title}`} style={{ backgroundImage: `url(${submenuItems[index].thumbnail_image.sizes['large']})` }} onClick={this.goToPage} >
                                        <div className="card-gradient" />
                                        <div className="card-img-overlay">
                                            <h3 className="default-secondary-color">{submenuItems[index++].title}<div className="deco-border-small primary-bg-color" /></h3>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-12 two-third-height reset-inset">
                                    <Link to={`/${submenuItems[index].link.replace(BASE_URL, "pages/")}`} className="card card-overlay" title={`Go to ${submenuItems[index].title}`} style={{ backgroundImage: `url(${submenuItems[index].thumbnail_image.sizes['large']})` }} onClick={this.goToPage}>
                                        <div className="card-gradient" />
                                        <div className="card-img-overlay">
                                            <h3 className="default-secondary-color">{submenuItems[index++].title}<div className="deco-border-small primary-bg-color" /></h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 full-height">
                            <div className="row half-height">
                                <div className="col-12 reset-inset">
                                    <Link to={`/${submenuItems[index].link.replace(BASE_URL, "pages/")}`} className="card card-overlay" title={`Go to ${submenuItems[index].title}`} style={{ backgroundImage: `url(${submenuItems[index].thumbnail_image.sizes['large']})` }} onClick={this.goToPage}>
                                        <div className="card-gradient" />
                                        <div className="card-img-overlay">
                                            <h3 className="default-secondary-color">{submenuItems[index++].title}<div className="deco-border-small primary-bg-color" /></h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="row half-height">
                                <div className="col-6 reset-inset">
                                    <Link to={`/${submenuItems[index].link.replace(BASE_URL, "pages/")}`} className="card card-overlay" title={`Go to ${submenuItems[index].title}`} style={{ backgroundImage: `url(${submenuItems[index].thumbnail_image.sizes['large']})` }} onClick={this.goToPage}>
                                        <div className="card-gradient" />
                                        <div className="card-img-overlay">
                                            <h3 className="default-secondary-color">{submenuItems[index++].title} <div className="deco-border-small primary-bg-color" />
                                            </h3>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 half-height reset-inset">
                                            <Link to={`/${submenuItems[index].link.replace(BASE_URL, "pages/")}`} className="card card-overlay" title={`Go to ${submenuItems[index].title}`} style={{ backgroundImage: `url(${submenuItems[index].thumbnail_image.sizes['large']})` }} onClick={this.goToPage}>
                                                <div className="card-gradient" />
                                                <div className="card-img-overlay">
                                                    <h3 className="default-secondary-color">{submenuItems[index++].title} <div className="deco-border-small primary-bg-color" />
                                                    </h3>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-12 half-height reset-inset">
                                            <Link to={`/${submenuItems[index].link.replace(BASE_URL, "pages/")}`} className="card card-overlay" title={`Go to ${submenuItems[index].title}`} style={{ backgroundImage: `url(${submenuItems[index].thumbnail_image.sizes['large']})` }} onClick={this.goToPage}>
                                                <div className="card-gradient" />
                                                <div className="card-img-overlay">
                                                    <h3 className="default-secondary-color">{submenuItems[index++].title} <div className="deco-border-small primary-bg-color" />
                                                    </h3>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}