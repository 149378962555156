import React, { Component } from 'react';

export default class Description extends Component {
    render() {
        let {title, description, decoImage } = this.props;
        return (
            <>
                <div className="container-fluid mt-210">
                    <div className="row">
                        <div className="col-4 offset-md-1">
                            <h1 className="default-primary-color">{title}</h1>
                            <div className="deco-border-large primary-bg-color mt-60 mb-60" />
                            <div dangerouslySetInnerHTML={{ __html: description }}></div>
                        </div>
                        <div className="col-1" />
                        <div className="col-5">
                            {decoImage && 
                                <div className="fs-screen-img ch-500" style={{ backgroundImage: `url(${decoImage ? decoImage.sizes['large'] : ''})` }} />
                            }
                        </div>
                        <div className="col-1" />
                    </div>
                </div>
            </>
        )
    }
}