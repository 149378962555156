import React from 'react';
import LogoBlack from '../../assets/img/Builders-Logo-Black.png';

const getAlignment = align => {
    if (align === 'center') return ' txt-align-center';
    if (align === 'left') return ' txt-align-left';
    return '';
}

export const Heading1 = ({ title, align }) =>
    <div className="container-fluid mt-210">
        <div className="row">
            <div className={`col offset-md-1${getAlignment(align)}`}>
                <h1 className="default-primary-color">{title}</h1>
                <div className="deco-border-large primary-bg-color mt-60 mb-60" />
            </div>
        </div>
    </div>
export const Heading2 = ({ title, align }) =>
    <div className="row">
        <div className={`col-12${getAlignment(align)}`}>
            <h2 className="default-primary-color">{title}</h2>
            <div className={`deco-border-large primary-bg-color mt-60 mb-60${align === 'center' ? ' centered' : ''}`} />
        </div>
    </div>

export const Logo = () => <img className="mb-5" width={250} src={LogoBlack} alt="Builders Windows &amp; Doors" />
export const Text = ({ text }) => <div dangerouslySetInnerHTML={{ __html: text }}></div>
export const OneImage = ({ image }) => <img className="img-fluid" src={image ? image.sizes['showcase-img'] : ''} alt={image ? image.alt : ''} />

export const ThreeImages = ({ bottomImage, topImage, verticalImage, }) =>
    <div className="row">
        <div className="col-6 reset-inset">
            <div className="h-vertical-rectangle" style={{ backgroundImage: `url(${verticalImage ? verticalImage.sizes['img-md-pt'] : ''}` }}></div>
        </div>
        <div className="col-6">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="h-horizontal-rectangle" style={{ backgroundImage: `url(${topImage ? topImage.sizes['img-sm'] : ''}` }}></div>
                </div>
                <div className="col-12">
                    <div className="h-horizontal-rectangle" style={{ backgroundImage: `url(${bottomImage ? bottomImage.sizes['img-sm'] : ''}` }}></div>
                </div>
            </div>
        </div>
    </div>