import React, { Component } from 'react';
import ReactCompareImage from 'react-compare-image';

export default class SliderShowCase extends Component {
    render() {
        let { leftImage, rightImage, button_title, button_link } = this.props;
        if (!leftImage || !rightImage) return null;
        return (
            <div className="container-fluid mb-120 pt-90">
                <div className="row mt-120 mb-120">
                    <div className="col-2" />
                    <div className="col-8">
                        <ReactCompareImage
                            leftImage={leftImage.sizes['showcase-img']}
                            rightImage={rightImage.sizes['showcase-img']}
                            sliderPositionPercentage='0.5'
                            sliderLineWidth='2'
                            sliderLineColor='#FFFFFF'
                        />
                    </div>
                    <div className="col-2" />
                </div>
                {
                    button_title &&
                    <div className="row mt-120">
                        <div className="col-4 offset-md-4 txt-align-center">
                            <a href={button_link} target="_blank" className="btn btn-primary" rel="noopener noreferrer">{button_title}</a>
                        </div>
                    </div>
                }
            </div>
        );
    }
}