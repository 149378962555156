import React, { Component } from 'react';
import {
      Validator,
      TextField,
      ValueSetValidator,
      EmailValidator,
} from '../../input';

export default class Contact extends Component {

      constructor(props) {
            super(props);
            this.validator = new Validator();
      }

      register = e => {
            if (this.props.register) {
                  this.props.register(this.validator.validResult());
            }
            e.preventDefault();
      }

      render() {
            let { showFormError, showFormSuccess, registrationLoading } = this.props;
            showFormError = showFormError || false;
            showFormSuccess = showFormSuccess || false;

            let sendStatus = 'initial';
            if (registrationLoading) sendStatus = 'processing';
            else if (showFormSuccess) sendStatus = 'done';

            return (
                  <form className="form mrg-btm form-row">
                        <div className="col-12">
                              {showFormError &&
                                    <div id="feedback" className="mb-5">
                                          <div className="alert alert-danger">
                                                <p className="default-primary-color">
                                                      <i className="fas fa-exclamation-triangle"></i> There are some fields missing or incorrect. Please check them and try again.
                                                </p>
                                          </div>
                                    </div>
                              }
                              {showFormSuccess &&
                                    <div id="feedback" className="mb-5">
                                          <div className="alert alert-success">
                                                <p className="default-primary-color">
                                                      <i className="fas fa-check-circle"></i> Thank you for your filling in the contact form. An email has been send to Builders.
                                                </p>
                                          </div>
                                    </div>
                              }
                        </div>
                        <div className="col-5">
                              <div className="form-group">
                                    <TextField
                                          id='fullName'
                                          placeholder='Full Name'
                                          validator={new ValueSetValidator(this.validator)}
                                    />
                              </div>
                              <div className="form-group">
                                    <TextField
                                          id='phonenumber'
                                          placeholder='Phone Number'
                                          validator={new ValueSetValidator(this.validator)}
                                    />
                              </div>
                              <div className="form-group">
                                    <TextField
                                          id='email'
                                          placeholder='Email Address'
                                          validator={new EmailValidator(this.validator)}
                                    />
                              </div>
                              <div className="form-group">
                                    <TextField
                                          id='companyName'
                                          placeholder='Company Name'
                                          validator={new ValueSetValidator(this.validator)}
                                    />
                              </div>
                              <div className="form-group">
                                    <select id="subject" className="form-control" required>
                                          <option value="---">Choose</option>
                                          <option value="subject">Subject 1</option>
                                          <option value="subject">Subject 2</option>
                                          <option value="other">Other</option>
                                    </select>
                              </div>
                        </div>
                        <div className="col-1" />
                        <div className="col-6">
                              <div className="form-group">
                                    <textarea
                                          id='message'
                                          placeholder='Your message...'
                                    />
                              </div>
                              <div className="form-group">
                                    <button className={"btn btn-primary pull-right " + sendStatus} onClick={this.register} disabled={(['processing', 'done'].includes(sendStatus))}>
                                          {sendStatus === 'initial' && 'Send'}
                                          {sendStatus === 'processing' && <div>Submitting</div>}
                                          {sendStatus === 'done' && 'Done!'}
                                    </button>
                              </div>
                        </div>
                  </form>
            );
      }
}