import React, { Component } from 'react';

export default class ColorPalettesGenieEffect extends Component {
    render() {
        let { colors, colorsTitle } = this.props;
        return (
            <div id="palettes" className="container-fluid">
                <div className="row mb-60">
                    <div className="col-12 txt-align-center">
                        <div className="deco-border-large primary-bg-color mt-60 mb-60 centered" />
                        <h2 className="default-primary-color mb-120">{colorsTitle}</h2>
                        {colors &&
                            <div id="dock-container">
                                <div id="dock">
                                    <ul>
                                        {colors.map(({ image, name }, i) =>
                                            <li key={`color-palette-${i}`}>
                                                <span>{name}</span>
                                                <img src={image ? image.sizes['thumbnail'] : ''} alt={image ? image.alt : ''} />
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}