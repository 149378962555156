import React, { Component } from 'react';

// const logo = type => {
//     switch (type) {
//         case 'Black': return require('../../assets/img/Builders-Logo-Black.png');
//         case 'Red':
//         default: return require('../../assets/svg/logo-icon.svg');
//     }
// }

export default class AboutSection extends Component {
    render() {
        let { aboutDescription } = this.props;
        return (
            <div className="container-fluid pt-120">
                <div className="row">
                    <div className="col-2" />
                    <div className="col-8 txt-align-center pt-90">
                        <p className="default-primary-color center-justified">{aboutDescription}</p>
                        <div className="deco-border-large primary-bg-color mt-210 mb-210 centered" />
                    </div>
                    <div className="col-2" />
                </div>
            </div>
        );
    }
}