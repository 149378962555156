import React, { Component } from 'react';
import { BASE_URL } from '../../constant';

// IMG and SVG's
import LogoIconRed from '../../assets/svg/logo-icon.svg';

const contactTypes = {
    'telephone': 'fas fa-phone',
    'email': 'fas fa-envelope',
    'openingHours': 'far fa-clock',
    'address': 'fas fa-location-arrow',
    'fax': 'fas fa-fax',
}

export default class Aside extends Component {
    constructor() {
        super();
        this.state = {
            fetching: true,
        };
    }
    componentDidMount() {
        fetch(`${BASE_URL}get-layout/?post-type=options-aside`)
            .then(res => res.json())
            .then(aside => this.setState({ ...aside, fetching: false }));
    }

    render() {
        let { fetching, contact_info, social_links, social_links_title, } = this.state;
        if (fetching) return null;
        return (
            <aside id="complementary" className="default-secondary-bg-color">
                <div className="container double-inset">
                    <div className="col-4 offset-md-4 txt-align-center">
                        <img className="brand" src={LogoIconRed} alt="Builders Windows &amp; Doors" />
                    </div>
                </div>
                <div className="container double-inset">
                    <div className="row align-items-center">
                        {contact_info &&
                            <div className="col-4">
                                <table className="table table-borderless">
                                    <tbody>
                                        {contact_info.map(({ contact_type, contact_info }, i) =>
                                            <tr key={`${i}-contact-info-row`}>
                                                <td>
                                                    <i className={`${contactTypes[contact_type]} default-primary-color`}></i>
                                                </td>
                                                {contact_type === 'email' && <td><a href={`mailto:${contact_info}`}>{contact_info}</a></td>}
                                                {contact_type === 'telephone' && <td><a href={`tel:${contact_info}`}>{contact_info}</a></td>}
                                                {contact_type === 'openingHours' &&
                                                    <td>{contact_info}</td>
                                                }
                                                {contact_type === 'address' &&
                                                    <td>{contact_info}</td>
                                                }
                                                {contact_type === 'fax' &&
                                                    <td>{contact_info}</td>
                                                }
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        }
                        <div className="col-4 txt-align-center"></div>
                        <div className="col-4">
                            {social_links_title && <h3 className="default-primary-color txt-align-center">{social_links_title}</h3>}
                            {
                                social_links &&
                                <ul className="list list-social">
                                    {social_links.map(({ link, title, type }, i) =>
                                        <li key={`social-links-${i}`}>
                                            <a href={link} target="_blank" rel="noopener noreferrer" title={title}>
                                                <i className={`fab fa-${type} default-primary-color`}></i>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            }
                        </div>
                    </div>
                </div>
            </aside>
        );
    }
}