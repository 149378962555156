import React, { Component } from 'react';

// SVG
import MissingIco from '../../assets/svg/missing-icon.svg';
import DesignIcon from '../../assets/svg/icons_design.svg';
import EnergyEfficientIcon from '../../assets/svg/icons_energy.svg';
import SecurityIcon from '../../assets/svg/icons_security.svg';
import ServiceIcon from '../../assets/svg/icons_services.svg';
import DurabilityIcon from '../../assets/svg/icons_durability.svg';
import QualityIcon from '../../assets/svg/icons_quality.svg';

const icons = {
    designIcon: DesignIcon,
    energyEfficientIcon: EnergyEfficientIcon,
    securityIcon: SecurityIcon,
    serviceIcon: ServiceIcon,
    durabilityIcon: DurabilityIcon,
    qualityIcon: QualityIcon,
}

export default class SetUsAppartSection extends Component {
    render() {
        let { qualities, title, typeOfTitle, addBreakBetweenTitle, thinTitle, boldTitle  } = this.props;
        return (
            <div id="benefits" className="container-fluid triple-inset mb-5">
                <div className="row">
                    <div className="col">
                        <h2 className="default-primary-color mb-60 txt-align-center">
                            {typeOfTitle === 'thinBold' &&
                                <>
                                    <span className="thin">{thinTitle}</span>{addBreakBetweenTitle === true ? <br /> : <span className="spacing"></span>}
                                    <span className="bold">{boldTitle}</span>
                                </>
                            }
                            {typeOfTitle === 'normal' &&
                                <>
                                    {title}
                                </>
                            }
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-2" />
                    <div className="col-8">
                        <div className="row">
                            {qualities && qualities.map(({ title, icon }, i) =>
                                <div key={`quality-home-${i}`} className="col-4">
                                    <div className="card card-benefits txt-align-center">
                                        {<img src={icon ? icons[icon] : MissingIco} alt={icon.alt} className="icons-appart pt-60" title={title} />}
                                        <h3 className="tertiary-color mt-60 mb-60">{title}</h3>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-2" />
                </div>
            </div>
        );
    }
}