import React, { Component } from 'react';

export default class VideoPlayer extends Component {
    render() {
        let { videoID } = this.props;
        return (
            <div className="container-fluid pb-5">
                <div className="row pb-5">
                    <div className="col-2" />
                    <div className="col-8">
                        <div className="embed-responsive embed-responsive-16by9">
                            <div id="video-wrapper">
                                <iframe
                                    title='yt-video-embed'
                                    src={`https://www.youtube.com/embed/${videoID}`}
                                    frameBorder='0'
                                    allow='autoplay; encrypted-media'
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-2" />
                </div>
            </div>
        );
    }
}