import React, { Component } from 'react';

export default class BlockQuote extends Component {
    render() {
        let { addQuote, quote_title, backgroundImage, quote, add_link, link, link_text, external_link, addExternalLink } = this.props;
        if (!addQuote) return null;
        return (
            <div className="container-fluid fs-screen fs-screen-img fs-screen-img-parallax mt-90" style={{ backgroundImage: `url(${backgroundImage ? backgroundImage.sizes['img-fullscreen'] : ''})` }}>
                <div className="masked"></div>
                <div className="row align-items-center">
                    <div className="col txt-align-center">
                        <h2 className="default-secondary-color">{quote_title}</h2>
                        <p className="default-secondary-color">{quote}</p>
                        {add_link && 
                            <a href={addExternalLink === true ? external_link : link} target={addExternalLink === true ? "_blank" : ""} className="btn btn-primary">{link_text}</a>
                        }
                    </div>
                </div>
            </div>
        );
    }
}