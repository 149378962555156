import React, { Component } from 'react';
import Carousel from '../subComponents/carousel';
import Slider from "react-slick";

export default class DescriptionGallery extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        let {title, description, decoImages } = this.props;
        return (
            <>
                <div className="container-fluid mt-210">
                    <div className="row">
                        <div className="col-4 offset-md-1">
                            <h1 className="default-primary-color">{title}</h1>
                            <div className="deco-border-large primary-bg-color mt-60 mb-60" />
                            <div dangerouslySetInnerHTML={{ __html: description }}></div>
                        </div>
                        <div className="col-1" />
                        <div className="col-3 carouselContainerCol3">
                            <div className="carouselContainer">
                                <Slider ref={c => (this.slider = c)} {...settings}>
                                    {decoImages && decoImages.map(({ ...props }, i) =>
                                        <Carousel key={`carousel-item-${i}`} {...props} />
                                    )}
                                </Slider>
                                <div style={{ textAlign: "center" }}>
                                    <div className="button previous" onClick={this.previous}>
                                        <i className="fal fa-chevron-left primary-color"></i>
                                    </div>
                                    <div className="button next" onClick={this.next}>
                                        <i className="fal fa-chevron-right primary-color"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1" />
                    </div>
                </div>
            </>
        )
    }
}