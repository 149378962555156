import React, { Component } from 'react';
import {
    Heading2,
    Text,
    OneImage,
    ThreeImages,
} from './dual-content-blocks';

const getCol = (split, side) => {
    switch (split) {
        case '25/75': return side === 'left' ? 3 : 9;
        case '33/66': return side === 'left' ? 4 : 8;
        case '40/60': return side === 'left' ? 5 : 7;
        case '60/40': return side === 'left' ? 7 : 5;
        case '66/33': return side === 'left' ? 8 : 4;
        case '75/25': return side === 'left' ? 9 : 3;
        case 'None':
        case '50/50':
        default: return 6;
    }
}

export default class DualContent extends Component {

    render() {
        let { dualContent } = this.props;
        return (
            <div className="container-fluid ">
                {dualContent && dualContent.map(({ leftContent, rightContent, split, offset, alignCenter }, i) =>
                    <div key={`dual-content-block-${i}`} className={`row mrg-btm${i > 0 ? ' mrg-top-triple' : ''}${alignCenter ? ' align-items-center' : ''}`}>
                        <div className={`col-${getCol(split, 'left')} offset-md-1`}>
                            {leftContent && leftContent.map(({ acf_fc_layout, ...props }, i) =>
                                <React.Fragment key={`left-content-block-row-${i}`}>
                                    {acf_fc_layout === 'heading' && <Heading2 title={props.heading} />}
                                    {acf_fc_layout === 'text' && <Text {...props} />}
                                    {acf_fc_layout === 'oneImage' && <OneImage {...props} />}
                                    {acf_fc_layout === 'threeImages' && <ThreeImages {...props} />}
                                </React.Fragment>
                            )}
                        </div>
                        <div className="col-1"></div>
                        <div className={`col-${getCol(split, 'right') - offset}`}>
                            {rightContent && rightContent.map(({ acf_fc_layout, ...props }, i) =>
                                <React.Fragment key={`right-ontent-block-row-${i}`}>
                                    {acf_fc_layout === 'heading' && <Heading2 title={props.heading} />}
                                    {acf_fc_layout === 'text' && <Text {...props} />}
                                    {acf_fc_layout === 'oneImage' && <OneImage {...props} />}
                                    {acf_fc_layout === 'threeImages' && <ThreeImages {...props} />}
                                </React.Fragment>
                            )}
                        </div>
                        <div className="col-1" />
                    </div>
                )}
            </div>
        )
    }
}