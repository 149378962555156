import React, { Component } from 'react';

export default class ParallaxSection extends Component {
    render() {
        let { title, subtitle, addButton, buttonLink, buttonText, backgroundImage } = this.props;
        let bgImg = backgroundImage ? backgroundImage.sizes['img-fullscreen'] : '';
        return (
            <div>
                <div className="container-fluid fs-screen-img fs-screen-img-bottom fs-screen-img-parallax" style={{ backgroundImage: `url(${bgImg})` }}>
                    <div className="masked"></div>
                    <div className="row align-items-center h-550">
                        <div className="col txt-align-center">
                            {title && <h2 className="default-secondary-color mb-0 as-h1">{title}</h2>}
                            {subtitle && <p className="default-secondary-color">{subtitle}</p>}
                            {buttonText &&
                                addButton && <a href={buttonLink} className="btn btn-primary">{buttonText}</a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}