import React, { Component } from 'react';
import PreloaderImage from '../../assets/img/preloader.gif';

export default class Preloader extends Component {
    render() {
        
        return (
            <div id="preloader">
                <img src={PreloaderImage} alt="Loading Builders" />
            </div>
        );
    }
}