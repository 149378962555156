import React, { Component } from 'react';

const Image = ({ image, enhancedHeight, backgroundPosition }) =>
    <div className="row mb-15">
        <div className="col">
            <div className={`fs-screen-img ${enhancedHeight ? 'ch-750' : 'ch-500'}`} style={{ 
                backgroundImage: `url(${image ? image.sizes['img-fullscreen'] : ''})`, 
                backgroundPosition: backgroundPosition
            }} />
        </div>
    </div>

const TwoImages = ({ leftImage, rightImage, ratio, backgroundPosition }) => {
    let leftRatio = 'col-6';
    let rightRatio = 'col-6';
    let leftImageSize = 'gallery-half-img';
    let rightImageSize = 'gallery-half-img';

    switch (ratio) {
        case '40-60':
            leftRatio = 'col-4 pr-0';
            rightRatio = 'col-8';
            leftImageSize = 'gallery-third-img';
            rightImageSize = 'gallery-two-thirds-img';
        break;
        case '60-40':
            leftRatio = 'col-8';
            rightRatio = 'col-4 pl-0';
            leftImageSize = 'gallery-two-thirds-img';
            rightImageSize = 'gallery-third-img';
        break;
        case '50-50':
        default: leftRatio = 'col-6 pr-0'; rightRatio = 'col-6'; break;
    }
    return (
        <div className="row mb-15 align-items-end">
            <div className={leftRatio}>
                <div className="fs-screen-img ch-500" style={{ 
                    backgroundImage: `url(${leftImage ? leftImage.sizes[leftImageSize] : ''})`,
                    backgroundPosition: backgroundPosition
                }} />
            </div>
            <div className={rightRatio}>
                <div className="fs-screen-img ch-500" style={{ 
                    backgroundImage: `url(${rightImage ? rightImage.sizes[rightImageSize] : ''})`, 
                    backgroundPosition: backgroundPosition
                }} />
            </div>
        </div>
    )
}

const ThreeImages = ({ leftImage, centerImage, rightImage, portrait, backgroundPosition }) =>
    <div className="row mb-15">
        <div className="col-4 pr-0">
            <div className={`fs-screen-img ${portrait ? 'ch-1000' : 'ch-500'}`} style={{ 
                backgroundImage: `url(${leftImage ? leftImage.sizes[portrait ? 'gallery-third-pt-img' : 'gallery-third-img'] : ''})`,
                backgroundPosition: backgroundPosition
            }} />
        </div>
        <div className="col-4">
            <div className={`fs-screen-img ${portrait ? 'ch-1000' : 'ch-500'}`} style={{ 
                backgroundImage: `url(${centerImage ? centerImage.sizes[portrait ? 'gallery-third-pt-img' : 'gallery-third-img'] : ''})`, 
                backgroundPosition: backgroundPosition  
            }} />
        </div>
        <div className="col-4 pl-0">
            <div className={`fs-screen-img ${portrait ? 'ch-1000' : 'ch-500'}`} style={{ 
                backgroundImage: `url(${rightImage ? rightImage.sizes[portrait ? 'gallery-third-pt-img' : 'gallery-third-img'] : ''})`, 
                backgroundPosition: backgroundPosition      
            }} />
        </div>
    </div>

const FourImages = ({ imageSet, verticalImage, mirrored, backgroundPosition }) => {
    return (
        <div className="row mb-15">
            {imageSet &&
                <div className={`col-8${mirrored ? ' order-last' : ''}`}>
                    <div className="row">
                        <div className="col-12">
                            <div className="fs-screen-img ch-500" style={{ 
                                backgroundImage: `url(${imageSet.topHorizontalImage ? imageSet.topHorizontalImage.sizes['img-fullscreen'] : ''})`,
                                backgroundPosition: backgroundPosition   
                            }} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 pr-0">
                            <div className="fs-screen-img ch-500" style={{ 
                                backgroundImage: `url(${imageSet.bottomLeftImage ? imageSet.bottomLeftImage.sizes['img-fullscreen'] : ''})`,
                                backgroundPosition: backgroundPosition   
                            }} />
                        </div>
                        <div className="col-6">
                            <div className="fs-screen-img ch-500" style={{ 
                                backgroundImage: `url(${imageSet.bottomRightImage ? imageSet.bottomRightImage.sizes['gallery-third-img'] : ''})`,
                                backgroundPosition: backgroundPosition   
                            }} />
                        </div>
                    </div>
                </div>
            }
            <div className={`col-4${mirrored ? ' pr-0' : ' pl-0'}`}>
                <div className="fs-screen-img ch-1000" style={{ 
                    backgroundImage: `url(${verticalImage ? verticalImage.sizes['gallery-third-pt-img'] : ''})`,
                    backgroundPosition: backgroundPosition   
                }} />
            </div>
        </div>
    )
}

const FivesImages = ({ imageSet, verticalImage, mirrored, backgroundPosition }) => {
    return (
        <div className="row mb-15">
            {imageSet &&
                <div className={`col-8${mirrored ? ' order-last' : ''}`}>
                    <div className="row">
                        <div className="col pr-0">
                            <div className="fs-screen-img ch-500" style={{ 
                                backgroundImage: `url(${imageSet.topLeftImage ? imageSet.topLeftImage.sizes['img-fullscreen'] : ''})`,
                                backgroundPosition: backgroundPosition
                            }} />
                        </div>
                        <div className="col">
                            <div className="fs-screen-img ch-500" style={{ 
                                backgroundImage: `url(${imageSet.topRightImage ? imageSet.topRightImage.sizes['img-fullscreen'] : ''})`,
                                backgroundPosition: backgroundPosition
                            }} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col pr-0">
                            <div className="fs-screen-img ch-500" style={{ 
                                backgroundImage: `url(${imageSet.bottomLeftImage ? imageSet.bottomLeftImage.sizes['img-fullscreen'] : ''})`,
                                backgroundPosition: backgroundPosition
                            }} />
                        </div>
                        <div className="col">
                            <div className="fs-screen-img ch-500" style={{ 
                                backgroundImage: `url(${imageSet.bottomRightImage ? imageSet.bottomRightImage.sizes['img-fullscreen'] : ''})`, 
                                backgroundPosition: backgroundPosition
                            }} />
                        </div>
                    </div>
                </div>
            }
            <div className={`col-4${mirrored ? ' pr-0' : ' pl-0'}`}>
                <div className="fs-screen-img ch-1000" style={{ 
                    backgroundImage: `url(${verticalImage ? verticalImage.sizes['gallery-third-pt-img'] : ''})`,
                    backgroundPosition: backgroundPosition
                }} />
            </div>
        </div>
    )
}


export default class GalleryBlocks extends Component {
    render() {
        let { gallerySection } = this.props;
        if (!gallerySection) return null;
        return (
            <div className="container-fluid mt-210 mb-150">
                {gallerySection.map(({ acf_fc_layout, ...props }, i) =>
                    <React.Fragment key={`gallery-section-${i}`}>
                        {acf_fc_layout === 'oneImage' && <Image {...props} />}
                        {acf_fc_layout === 'twoImages' && <TwoImages {...props} />}
                        {acf_fc_layout === 'threeImages' && <ThreeImages {...props} />}
                        {acf_fc_layout === 'fourImages' && <FourImages {...props} />}
                        {acf_fc_layout === 'fiveImages' && <FivesImages {...props} />}
                    </React.Fragment>
                )}
            </div >
        );
    }
}