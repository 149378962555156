import React, { Component } from 'react';
import Slider from "react-slick";
import Preloader from '../subComponents/preloader';

export default class ShowRoomPage extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        let { fetching, title, description, showroom, button_title, button_link, typeOfTitleNew, addBreakBetweenTitle, thinTitle, boldTitle } = this.props;

        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        if (!title && !description && !showroom) return null;
        if (fetching) return <Preloader />;
        return (
            <div id="door-showroom" className="triple-inset">
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-6 txt-align-center mb-5">
                            <h2 className="default-primary-color">
                                {typeOfTitleNew === 'thinBold' &&
                                    <>
                                        <span className="thin">{thinTitle}</span>{addBreakBetweenTitle === true ? <br /> : <span className="spacing"></span>}
                                        <span className="bold">{boldTitle}</span>
                                    </>
                                }
                                {typeOfTitleNew === 'normal' &&
                                    <>
                                        {title}
                                    </>
                                }    
                            </h2>
                            <div className="deco-border-large primary-bg-color mt-60 mb-60 centered"></div>
                            <p>{description}</p>
                        </div>
                    </div>
                </div>
                {
                    showroom &&
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-1" />
                            <div className="col-10">
                                <div className="row mh-550">
                                    <div className="col-1" />
                                    <div className="col-1" />
                                    <div className="col-8 show-room-carousel">
                                        <Slider ref={c => (this.slider = c)} {...settings}>
                                            {
                                                showroom.map(({ ID, sizes, title, alt }, i) =>
                                                    <div key={`showroom-image-slide-${ID}-${i}`} className="slider-item">
                                                        <div className="show-room-item" title={title || alt}>
                                                            <img className="img-fluid" src={sizes['img-fullscreen-sq']} alt={alt} />
                                                        </div>
                                                    </div>)
                                            }
                                        </Slider>
                                        <div style={{ textAlign: "center" }}>
                                            <div className="button previous" onClick={this.previous}>
                                                <i className="fal fa-chevron-left primary-color"></i>
                                            </div>
                                            <div className="button next" onClick={this.next}>
                                                <i className="fal fa-chevron-right primary-color"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-1" />
                        </div>
                        {
                            button_title &&
                            <div className="row mt-120">
                                <div className="col-4 offset-md-4 txt-align-center">
                                    <a href={button_link} target="_blank" className="btn btn-primary" rel="noopener noreferrer">{button_title}</a>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}