import React, { Component } from 'react';
import { BASE_URL } from '../../constant';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        fetch(`${BASE_URL}get-layout/?post-type=options-footer`)
            .then(res => res.json())
            .then(footer => this.setState({ ...footer, fetching: false }));
    }
    
    render() {
        let { fetching, download_link, company_name } = this.state;
        if (fetching) return null;
        return (
            <footer id="footer" className="tertiary-bg-color inset">
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <ul className="list list-footer">
                                <li>
                                    <a href="/pages/sitemap.xml" className="default-secondary-color" title="title">Sitemap</a>
                                </li>
                                {download_link && download_link.map(({ file, text }, i) =>
                                    <li key={`${i}-download-link-footer`}>
                                        <a href={file ? file.url : ''} className="default-secondary-color" title="title">{text}</a>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="col-4 txt-align-center">
                            <p className="default-secondary-color">&copy;Copyright {new Date().getFullYear()}, {company_name}</p>
                        </div>
                        <div className="col-4 align-right">
                            <p className="default-secondary-color">Engineered by: <a href="https://www.cr38te.com" title="Engineered by CR38TE" rel="noopener noreferrer" target="_blank">CR38TE</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}