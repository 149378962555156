import React, { Component } from 'react';
import BlackMarker from '../../assets/img/black_marker.png';

export default class GoogleMaps extends Component {

      componentDidMount() {
            var oLatLng = {
                  lat: 12.5123946,
                  lng: -70.0205195
              };

              var map = new window.google.maps.Map(document.getElementById('map'), {
                  center: oLatLng,
                  zoom: 15,
                  backgroundColor : '#dddddd',
                  disableDoubleClickZoom : false,
                  keyboardShortcuts : false,
                  scrollwheel : false,
                  draggable : true,
                  panControl : true,
                  arrowPosition : 30,
                  arrowStyle : 2,
                  arrowSize : 15,
                  disableDefaultUI : true,
                  zoomControl : true,
                  styles: [
                        {
                            "featureType": "administrative",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#444444"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#f2f2f2"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "saturation": -100
                                },
                                {
                                    "lightness": 45
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#ed1c24"
                                },
                                {
                                    "visibility": "on"
                                }
                            ]
                        }
                    ]
              });

              var marker = new window.google.maps.Marker({
                  position: oLatLng,
                  map: map,
                  icon: BlackMarker,
                  url: 'https://www.google.com/maps/place/Builders+Windows+%26+Doors/@12.5196557,-70.0294928,17z/data=!4m5!3m4!1s0x8e853894d904fae1:0x8e16bbb61ad990c4!8m2!3d12.5191477!4d-70.0273792',
              });

              window.google.maps.event.addListener(marker, 'click', function() {
                  window.location.href = this.url;
              });
      }
      render() {
            return (
                  <div id="map"></div>
            );
      }
}