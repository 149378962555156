import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom';
import { BASE_URL } from './constant';

// STYLESHEET
import './assets/css/app.min.css';

// COMPONENTS => GLOBAL
import Header from './components/header/header';
import Aside from './components/aside/aside';
import Footer from './components/footer/footer';
import DynamicScrollToTop from './components/dynamicScrollToTop';

// COMPONENTS => PAGES
import Homepage from './components/pages/homePage';
import ProductsOverview from './components/pages/productOverview';
import CompanyPage from './components/pages/company';
import ContactPage from './components/pages/contact/index';
//import BookingPage from './components/pages/booking';

const components = {
    'index': Homepage,
    'company.php': CompanyPage,
    'contact.php': ContactPage,
}

const getPageRoute = ({ id, template, slug, parent }) => {
    let Template = components[template];
    if (!Template) Template = components['index'];
    return (
        <Route key={id} path={`/pages/${slug}`} exact render={props => <Template pageID={id} slug={slug} {...props} />} />
    )
}

export default class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageList: []
        }
    }

    componentDidMount() {
        const endpoint = `${BASE_URL}wp-json/wp/v2/pages?per_page=20`;
        fetch(endpoint)
            .then(response => response.json())
            .then(pageList => this.setState({ pageList }))
            .catch(error => console.error(error))
    }

    render() {
        let { pageList } = this.state;
        return (
            <Router>
                <div className="App">
                    <DynamicScrollToTop />
                    <Header />
                    <Route path="/pages" render={(props) => <Homepage siteURL={BASE_URL} slug='home' {...props} />} exact />
                    {pageList.map(page => getPageRoute(page))}
                    <Route exact path="/pages/products/:slug" component={ProductsOverview} />
                    <Aside />
                    <Footer />
                </div>
            </Router>
        );
    }
}