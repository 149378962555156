import React, { Component } from 'react';

export default class Carousel extends Component {
    render() {
        let {sizes, caption} = this.props;
        return (
            <div className="row">
                <div className="col-12 txt-align-center">
                    <img className="img-responsive" src={sizes.large} alt="alternative" />
                    {caption && <p className="mt-60">{caption}</p>}
                </div>
            </div>
        )
    }
}