import React, { Component } from 'react';
import { BASE_URL } from '../../constant';
import Preloader from '../subComponents/preloader';

// COMPONENTS
import DynamicScrollToTop from '../dynamicScrollToTop';
import ColorPalettes from '../subComponents/colorPalettesGenieEffect';
import ParallaxSection from '../subComponents/parallaxSection';
import Showroom from './showRoom';
import SliderShowCase from '../subComponents/sliderShowcase';
import Description from '../subComponents/description';
import DescriptionGallery from '../subComponents/descriptionGallery';
import ButtonExternal from '../subComponents/button';

// UPLOADS
import GalleryBlocks from '../subComponents/galleryBlocks';
import Link from '../subComponents/link';

export default class ProductsOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
        };
    }

    getPageData = slug => {
        fetch(`${BASE_URL}get-posts/?post-type=page&slug=${slug}`)
            .then(res => res.json())
            .then(page => this.setState({ ...page, fetching: false }));
    }

    componentDidMount() {
        this.getPageData(this.props.match.params.slug);
        fetch(`${BASE_URL}get-layout/?post-type=options-colors`)
            .then(res => res.json())
            .then(colors => this.setState({ colors }));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.slug !== this.props.match.params.slug) {
            this.getPageData(this.props.match.params.slug);
        }
    }

    render() {
        let { fetching, contentBlocks, colors } = this.state;
        if (fetching) return <Preloader />;
        return (
            <main id="main" className="pb-60 content-templates">
                <DynamicScrollToTop />
                {contentBlocks && contentBlocks.map(({ acf_fc_layout, ...props }, i) =>
                    <React.Fragment key={`gallery-section-${i}`}>
                        {acf_fc_layout === 'description' && <Description {...props} />}
                        {acf_fc_layout === 'descriptionGallery' && <DescriptionGallery {...props} />}
                        {acf_fc_layout === 'gallery' && <GalleryBlocks {...props} />}
                        {acf_fc_layout === 'showroom' && <Showroom {...props} />}
                        {acf_fc_layout === 'backgroundImageSection' && <ParallaxSection {...props} />}
                        {acf_fc_layout === 'sliderShowcase' && <SliderShowCase {...props} />}
                        {acf_fc_layout === 'colorPalette' && <ColorPalettes {...colors} />}
                        {acf_fc_layout === 'Button' && <ButtonExternal {...props} />}
                        {acf_fc_layout === 'link' && <Link {...props} />}
                    </React.Fragment>
                )}
            </main>
        );
    }
}