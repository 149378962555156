import React, { Component } from 'react';

export default class Link extends Component {
    render() {
        let { addImage, image, link, } = this.props;
        link = link || {};
        return (
            <div className="container-fluid reset-inset mt-5 mb-5">
                <div className="row">
                    <div className="col txt-align-center">
                        <a href={link.url} target={link.target}>{link.title}
                            {addImage && image && <img src={image ? image.url : ''} alt={image ? image.url : ''} />}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}