import React, { Component } from 'react';
import DynamicScrollToTop from '../dynamicScrollToTop';
import Preloader from '../subComponents/preloader';
import HeroArea from '../subComponents/heroArea';
import AboutSection from '../subComponents/aboutSection';
import ProductsMainSection from '../subComponents/productsMainSection';
import SliderShowCase from '../subComponents/sliderShowcase';
import BlockQuote from '../subComponents/blockQuote';
import SetUsAppartSection from '../subComponents/SetUsAppartSection';
import VideoPlayer from '../subComponents/videoPlayer';
import { BASE_URL } from '../../constant';
export default class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = { fetching: true };
    }

    componentDidMount() {
        fetch(`${BASE_URL}get-posts/?post-type=page&slug=${this.props.slug}`)
            .then(response => response.json())
            .then((page) => this.setState({ ...page, fetching: false }))
            .catch(error => console.error(error))
    }

    render() {
        let {
            fetching,
            aboutSection,
            heroArea,
            productSection,
            quoteSection,
            sliderShowcase,
            qualitiessection,
            videoSection,
        } = this.state;
        if (fetching) return <Preloader />;
        return (
            <main id="main">
                <DynamicScrollToTop />
                <HeroArea {...heroArea} />
                <AboutSection {...aboutSection} />
                <ProductsMainSection {...productSection} />
                <BlockQuote {...quoteSection} />
                <SliderShowCase {...sliderShowcase} />
                <SetUsAppartSection {...qualitiessection} />
                {videoSection.add_video_section && <VideoPlayer {...videoSection} />}
            </main>
        );
    }
}